import { createApp } from 'vue'
import App from './App.vue'
// Toast
import { showToast } from '@nutui/nutui';
import '@nutui/nutui/dist/packages/toast/style/css';

// Notify
import { showNotify } from '@nutui/nutui';
import '@nutui/nutui/dist/packages/notify/style/css';

// Dialog
import { showDialog } from '@nutui/nutui';
import '@nutui/nutui/dist/packages/dialog/style/css';

// ImagePreview
import { showImagePreview } from '@nutui/nutui';
import '@nutui/nutui/dist/packages/imagepreview/style/css';

// import router from './router'
import { Cell, CellGroup } from '@nutui/nutui'
import { Dialog } from '@nutui/nutui'





const app = createApp(App)

 app.config.productionTip = false
 app.config.devtools = true 

app.use(Cell)
app.use(CellGroup)
app.use(Dialog)
app.use(showToast)
app.use(showNotify)
app.use(showDialog)
app.use(showImagePreview)
app.mount('#app');





// new Vue({
//   render: h => h(App),
// }).$mount('#app')

