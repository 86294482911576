import axios from 'axios';
import { ref, createVNode } from 'vue';
import IcpNumberVue from './IcpNumber.vue';
import { showDialog } from '@nutui/nutui';
import '@nutui/nutui/dist/packages/dialog/style';
// const show = ref(false)

export default {
  name: 'Login',
  components: {
    IcpNumberVue
  },
  mounted() {
    this.getOpenId();
  },
  data() {
    return {
      //isLogin: true,
      LoginRequestVO: {
        userName: '',
        password: ''
      },
      LoginByWXRequestVO: {
        code: ''
      },
      notifyMsg: ''
    };
  },
  methods: {
    gotoRegister() {
      console.log('跳转注册页面');
      //跳转注册页面
      window.location.href = "register.html";
    },
    onLoginSubmit() {
      // 处理登录逻辑，例如发送请求到后端 API
      console.log('登录信息', this.LoginRequestVO);
      //生产环境
      let port = window.location.port;
      let url;
      if (port === "8080") {
        url = "https://www.huaminzhineng.com:8080/rest/loginService/loginByPassword";
      } else {
        url = "https://www.huaminzhineng.com/rest/loginService/loginByPassword";
      }

      // //测试环境
      // let url="http://localhost:8080/rest/loginService/loginByPassword";
      axios.post(url, this.LoginRequestVO).then(response => {
        console.log(JSON.stringify(response));
        if (response.data.success) {
          let token = response.data.data.token;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('id', response.data.data.id);
          sessionStorage.setItem('userName', response.data.data.userName);
          //问题出在下面这里
          let notifyMsg = response.data.msg;
          console.log(notifyMsg);
          showDialog({
            title: '温馨提示',
            content: notifyMsg,
            noCancelBtn: true
          });
          // show = true
          //跳转至主页
          setTimeout(function () {
            window.location.href = "homePage.html";
          }, 1500);
        } else {
          let notifyMsg = response.data.msg;
          console.log(notifyMsg);
          showDialog({
            title: '温馨提示',
            content: notifyMsg,
            noCancelBtn: true
          });
        }
      }).catch(e => {});

      //         //post请求
      // axios.post('/api/data', {}, {  
      //     headers: {  
      //         'Authorization': `Bearer ${token}`  
      //     }  
      // })
    },
    getWeChatCode() {
      let hdUrl = "https://www.huaminzhineng.com";
      hdUrl = encodeURIComponent(hdUrl);
      let wxUrl = "https://open.weixin.qq.com/connect/oauth2/authorize" + "?appid=wx88e65f57017ffb3f&redirect_uri=" + hdUrl + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
      window.location.href = wxUrl;
    },
    tipsClick(value) {
      showDialog({
        title: '温馨提示',
        content: value,
        noCancelBtn: true
      });
    },
    getOpenId() {
      var url1 = decodeURI(window.location.search);
      var object = {};
      if (url1.indexOf("?") != -1) {
        var str = url1.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
        let code = object["code"];
        //通过后端请求openId和accessToken  

        //生产环境 
        let port = window.location.port;
        let url;
        if (port === "8080") {
          url = "https://www.huaminzhineng.com:8080/rest/loginService/loginByWX";
        } else {
          url = "https://www.huaminzhineng.com/rest/loginService/loginByWX";
        }

        // //测试环境
        // let url="http://localhost:8080/rest/loginService/loginByWX";

        this.LoginByWXRequestVO.code = code;
        console.log(JSON.stringify(this.LoginByWXRequestVO));
        axios.post(url, this.LoginByWXRequestVO).then(response => {
          console.log(JSON.stringify(response));
          if (response.data.success) {
            let token = response.data.data.token;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('id', response.data.data.id);
            // sessionStorage.setItem('userName',response.data.data.userName)
            let notifyMsg = response.data.msg;
            console.log(notifyMsg);
            showDialog({
              title: '温馨提示',
              content: notifyMsg,
              noCancelBtn: true
            });
            // show = true
            //跳转至主页
            setTimeout(function () {
              // window.location.href="homePage.html"
              // window.location.href="https://www.huaminzhineng.com:8080/homePage.html"
              window.location.href = "homePage.html";
            }, 0);
          } else {
            let notifyMsg = response.data.msg;
            console.log(notifyMsg);
            showDialog({
              title: '温馨提示',
              content: notifyMsg,
              noCancelBtn: true
            });
          }
        }).catch(e => {
          this.tipsClick(JSON.stringify(e));
        });
      }
    }
    //         tipsClick() {
    //   showDialog({
    //     title: '温馨提示',
    //     content: '支持函数调用和组件调用两种方式。',
    //     noCancelBtn: true,
    //   })
    // }
  }
};